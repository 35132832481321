import React, {useEffect, useRef, useState, memo} from 'react';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {useRouter} from '../hooks/use-router';
import {useRetrieveBaselineWeeks} from '../hooks/use-analytics-api';
import {Notification} from '../navigation/page-layout';
import {translateErrorToReactNode} from '../common';
import {useCollection} from '@amzn/awsui-collection-hooks';
import {
    Box,
    Button,
    ColumnLayout,
    DateInput,
    Header,
    Modal,
    Multiselect,
    Pagination,
    SpaceBetween,
    Table,
    TextContent,
} from '@amzn/awsui-components-react';
import {recordUserEvent} from '../common/portal-analytics';
import {PortalEventName} from '../common/portal-event-name-enum';
import {useUploadBaselineWeekOverrides} from '../hooks/use-forecast-store-api';
import {BaselineWeekRecord} from '@amzn/f3-excelsior-analytics-api/clients/analyticsapiforexcelsioranalytics';

export interface BaselineWeekViewProps {
    pushNotification: (notification: Notification) => void;
}

export function BaselineWeekView(props: BaselineWeekViewProps) {
    console.log(`loading Baseline Week view...`);

    const auth = useAuth();
    const router = useRouter();
    const initialized = useRef(false);
    const isLoadingGrid = useRef<boolean>(true);
    const [isApplyingOverrides, setIsApplyingOverrides] = useState<boolean>(false);
    const itemsPerPage = 100;
    const [nodes, setNodes] = useState<string[]>([]);
    const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
    const [balanceWeeks, setBalanceWeeks] = useState<string[]>([]);
    const [selectedBalanceWeeks, setSelectedBalanceWeeks] = useState<string[]>([]);
    const [baselineWeekRecords, setBaselineWeekRecords] = useState<BaselineWeekRecord[]>([]);
    const [editedBaselineWeekRecords, setEditedBaselineWeekRecords] = useState<BaselineWeekRecord[]>([]);
    const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);

    const tenant = {
        business: auth.authInformation!.current!.businessId,
        country: auth.authInformation!.current!.country,
        flow: auth.authInformation!.current!.flow,
    };

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const clientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ExplainabilityView);
    const forecastStoreClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreEdit);

    const {
        execute: executeRetrieveBaselineWeeks,
        status: statusBaselineWeeks,
        value: getBaselineWeekResponse,
    } = useRetrieveBaselineWeeks(clientConfiguration, createErrorListener('RetrieveBaselineWeeks failed'), [auth]);

    isLoadingGrid.current = statusBaselineWeeks === 'idle' || statusBaselineWeeks === 'pending';

    const {execute: executeUploadBaselineWeekOverrides} = useUploadBaselineWeekOverrides(
        forecastStoreClientConfiguration,
        createErrorListener('UploadBaselineWeekOverrides failed'),
        [auth]
    );

    if (!initialized.current) {
        recordUserEvent({
            eventData: {
                ...tenant,
                email: auth.authInformation!.email,
                routerPathName: router.pathname,
                pageName: `Forecast Builder - Baseline Weeks`,
                loginTimeStamp: new Date().toLocaleString(),
            },
            eventName: PortalEventName.pageVisited,
        });

        executeRetrieveBaselineWeeks({
            ...tenant,
        });

        initialized.current = true;
    }
    useEffect(() => {
        if (!isLoadingGrid.current && getBaselineWeekResponse?.records && getBaselineWeekResponse.records.length > 0) {
            const records = getBaselineWeekResponse.records.map(
                (item) =>
                    ({
                        node: item.node,
                        balanceWeekStartDate: item.balanceWeekStartDate,
                        balanceWeekEndDate: item.balanceWeekEndDate,
                        baselineWeekStartDate: item.baselineWeekStartDate,
                        baselineWeekEndDate: item.baselineWeekEndDate,
                    } as BaselineWeekRecord)
            );
            setBaselineWeekRecords(records);

            const uniqueNodes: string[] = Array.from(new Set(getBaselineWeekResponse.records.map((record) => record.node)));
            setNodes(uniqueNodes);
            setSelectedNodes(uniqueNodes);
            const uniqueBalanceWeeks = Array.from(
                new Set(getBaselineWeekResponse.records.map((record) => record.balanceWeekStartDate!))
            );
            setBalanceWeeks(uniqueBalanceWeeks);
        }
    }, [isLoadingGrid.current]);

    const empty = (
        <Box textAlign="center" color="inherit">
            <b>No matching data</b>
            <Box variant="p" color="inherit">
                There is no matching data to display
            </Box>
        </Box>
    );

    const mergedRecords = baselineWeekRecords.map((record) => {
        // joins forecast records and edited records
        const editedRecord = editedBaselineWeekRecords.find(
            (editedRecord) => editedRecord.balanceWeekStartDate === record.balanceWeekStartDate && editedRecord.node === record.node
        );
        return editedRecord ?? record;
    });

    const {allPageItems, items, actions, collectionProps, paginationProps} = useCollection(mergedRecords, {
        filtering: {
            empty: empty,
            noMatch: empty,
            filteringFunction(item) {
                if (!selectedNodes.includes(item.node)) return false;
                else if (selectedBalanceWeeks.length) return selectedBalanceWeeks.includes(item.balanceWeekStartDate);
                return true;
            },
        },
        pagination: {pageSize: itemsPerPage},
        sorting: {
            defaultState: {
                sortingColumn: {sortingField: 'node'},
            },
        },
        selection: {
            keepSelection: true,
        },
    });

    const {selectedItems} = collectionProps;

    function onRecordEdit(row, column, newValue) {
        const item = {...row};

        // checks if record has already been edited, if so, update it. else append it
        setEditedBaselineWeekRecords((records) => {
            const existingItemIndex = records.findIndex(
                (record) => record.balanceWeekStartDate === item.balanceWeekStartDate && record.node === item.node
            );
            item[column.id] = newValue;
            if (existingItemIndex !== -1) {
                const updatedRecords = [...records];
                updatedRecords[existingItemIndex] = item;
                return updatedRecords;
            } else {
                return [...records, item];
            }
        });

        // checks if record is already selected, if so, edit it. else append it
        const existedSelectedItemIndex = selectedItems!.findIndex(
            (record) => record.balanceWeekStartDate === item.balanceWeekStartDate && record.node === item.node
        );
        if (existedSelectedItemIndex !== -1) {
            const records = [...selectedItems!];
            records[existedSelectedItemIndex] = item;
            actions.setSelectedItems(records);
        } else {
            actions.setSelectedItems([...selectedItems!, item]);
        }
    }

    function discardAllRecordEdits() {
        setEditedBaselineWeekRecords([]);
        actions.setSelectedItems([]);
    }

    function resetSelectedRows() {
        const selectedEditedRecords = editedBaselineWeekRecords.filter((item) => !selectedItems!.includes(item));
        setEditedBaselineWeekRecords(selectedEditedRecords);
        actions.setSelectedItems(selectedItems!.filter((item) => baselineWeekRecords.includes(item)));
    }

    useEffect(() => {
        actions.setSelectedItems(selectedItems!.filter((item) => selectedNodes.includes(item.node)));
    }, [selectedNodes]);

    return (
        <React.Fragment>
            <SpaceBetween size="s">
                <ColumnLayout columns={2}>
                    <div>
                        <Multiselect
                            selectedOptions={selectedNodes.map((item) => ({
                                label: item,
                                value: item,
                            }))}
                            onChange={async ({detail}) => {
                                const nodes = detail.selectedOptions.map((item) => item.value!);
                                setSelectedNodes(nodes);
                            }}
                            options={nodes.map((item) => ({
                                label: item,
                                value: item,
                            }))}
                            tokenLimit={5}
                            filteringType="auto"
                            placeholder="Select nodes/stores"
                            data-testid="nodes-multiselect"
                        />
                    </div>
                    <div>
                        <ColumnLayout columns={2}>
                            <div>
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        onClick={async (detail) => {
                                            setSelectedNodes(nodes);
                                        }}
                                        data-testid="select-all-nodes-button"
                                    >
                                        Select All
                                    </Button>
                                    <Button
                                        onClick={async (detail) => {
                                            setSelectedNodes([]);
                                        }}
                                        data-testid="deselect-all-nodes-button"
                                    >
                                        De-select All
                                    </Button>
                                </SpaceBetween>
                            </div>
                        </ColumnLayout>
                    </div>
                </ColumnLayout>
                <div>
                    <Table
                        {...collectionProps}
                        stickyHeader
                        stripedRows
                        selectionType="multi"
                        filter={
                            <React.Fragment>
                                <ColumnLayout columns={2}>
                                    <Multiselect
                                        selectedOptions={selectedBalanceWeeks.map((item) => ({
                                            label: item,
                                            value: item,
                                        }))}
                                        data-testid="balance-week-multiselect"
                                        onChange={async ({detail}) => {
                                            const weeks = detail.selectedOptions.map((item) => item.value!);
                                            setSelectedBalanceWeeks(weeks);
                                        }}
                                        options={balanceWeeks.map((item) => ({
                                            label: item,
                                            value: item,
                                        }))}
                                        tokenLimit={5}
                                        filteringType="auto"
                                        placeholder="Select Balance Weeks"
                                    />
                                </ColumnLayout>
                                <TextContent>
                                    <small>Applying filters may remove previously selected records</small>
                                </TextContent>
                            </React.Fragment>
                        }
                        header={
                            <Header
                                counter={
                                    selectedItems!.length
                                        ? `(${selectedItems!.length}/${
                                              allPageItems.filter((item) => selectedNodes.includes(item.node)).length
                                          })`
                                        : `(${allPageItems.filter((item) => selectedNodes.includes(item.node)).length})`
                                }
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button
                                            data-testid="select-all-rows-button"
                                            onClick={() => actions.setSelectedItems(allPageItems)}
                                        >
                                            Select All
                                        </Button>
                                        <Button data-testid="deselect-all-rows-button" onClick={() => actions.setSelectedItems([])}>
                                            De-select All
                                        </Button>
                                        <Button
                                            variant="normal"
                                            disabled={!selectedItems?.length}
                                            data-testid="discard-selected-changes-button"
                                            onClick={resetSelectedRows}
                                        >
                                            Reset Selected Rows
                                        </Button>
                                        <Button
                                            variant="normal"
                                            disabled={!editedBaselineWeekRecords.length}
                                            data-testid="discard-changes-button"
                                            onClick={discardAllRecordEdits}
                                        >
                                            Discard All Changes
                                        </Button>
                                        <Button
                                            variant="primary"
                                            disabled={!selectedItems?.length}
                                            data-testid="open-modal-button"
                                            onClick={({detail}) => {
                                                setConfirmModalVisible(true);
                                            }}
                                        >
                                            Apply Selections as Overrides
                                        </Button>
                                    </SpaceBetween>
                                }
                            >
                                Select Items as Overrides
                            </Header>
                        }
                        pagination={<Pagination {...paginationProps} />}
                        items={items}
                        submitEdit={onRecordEdit}
                        contentDensity="compact"
                        columnDefinitions={[
                            {
                                id: 'node',
                                header: 'Node',
                                cell: (item: BaselineWeekRecord) => item.node,
                                isRowHeader: true,
                                sortingField: 'node',
                            },
                            {
                                id: 'balanceWeekStartDate',
                                header: 'Balance Week Start Date',
                                cell: (item: BaselineWeekRecord) => item.balanceWeekStartDate,
                                isRowHeader: true,
                                sortingField: 'balanceWeekStartDate',
                            },
                            {
                                id: 'baselineWeekStartDate',
                                header: 'Baseline Week Start Date',
                                cell: (item: BaselineWeekRecord) => item.baselineWeekStartDate,
                                isRowHeader: true,
                                sortingField: 'baselineWeekStartDate',
                                editConfig: {
                                    editingCell: (item, {currentValue, setValue}) => {
                                        return (
                                            <DateInput
                                                autoFocus={true}
                                                value={currentValue ?? item.baselineWeekStartDate}
                                                onChange={(event) => setValue(event.detail.value)}
                                            />
                                        );
                                    },
                                },
                            },
                        ]}
                        loading={isLoadingGrid.current}
                        loadingText="Loading..."
                        empty={empty}
                    ></Table>
                </div>
            </SpaceBetween>
            <Modal
                data-testid="apply-overrides-modal"
                onDismiss={() => setConfirmModalVisible(false)}
                visible={confirmModalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                disabled={isApplyingOverrides}
                                variant="link"
                                onClick={({detail}) => {
                                    setConfirmModalVisible(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid="submit-button"
                                variant="primary"
                                disabled={isApplyingOverrides}
                                onClick={async ({detail}) => {
                                    setIsApplyingOverrides(true);
                                    await executeUploadBaselineWeekOverrides({
                                        businessId: tenant.business,
                                        country: tenant.country,
                                        flow: tenant.flow,
                                        records: selectedItems!.map((item) => ({
                                            node: item.node,
                                            balanceWeekStartDate: item.balanceWeekStartDate,
                                            baselineWeekStartDate: item.baselineWeekStartDate,
                                        })),
                                    });
                                    setIsApplyingOverrides(false);
                                    setConfirmModalVisible(false);
                                    discardAllRecordEdits();
                                    props.pushNotification({
                                        content: 'Overrides have been submitted for processing.',
                                        type: 'info',
                                    });
                                }}
                            >
                                Ok
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Apply Selections as Overrides"
            >
                {isApplyingOverrides
                    ? 'Overrides are being submitted for processing...'
                    : 'Are you sure you want to apply your selections as overrides to the forecast?'}

                <TextContent>
                    <small>Modified rows will be reset after submission.</small>
                </TextContent>
            </Modal>
        </React.Fragment>
    );
}

export default memo(BaselineWeekView);
